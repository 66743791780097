
import { defineComponent, reactive } from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useGradeBase } from '@/core/composables/GradeBase';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarDataInicialFinal from '@/core/components/Tela/SelecionarDataInicialFinal.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import Totalizador from '@/core/components/Tela/Totalizador.vue';
import storeSistema from '@/store/storeSistema';
import SelecionarEmpresa from '@/components/MeuSistema/Empresas/SelecionarEmpresa.vue';
import SelecionarEstoque from '@/components/Cadastros/Estoques/SelecionarEstoque.vue';
import SelecionarProduto from '@/components/Cadastros/Produtos/SelecionarProduto.vue';
import SelecionarPeriodoMovimento from '@/components/Estoques/MovimentoEstoque/SelecionarPeriodoMovimento.vue';
import SelecionarRecursoMovimento from '@/components/MeuSistema/SelecionarRecursoMovimento.vue';
import { IParametrosConsultaMovimentoEstoque } from '@/models/ParametrosRequisicao/Estoques/MovimentoEstoque/IParametrosConsultaMovimentoEstoque';
import { EPeriodoMovimentoEstoque } from '@/models/Enumeradores/Estoques/MovimentoEstoque/EPeriodoMovimentoEstoque';
import { ETipoMovimentoEstoque } from '@/models/Enumeradores/Estoques/MovimentoEstoque/ETipoMovimentoEstoque';
import ServicoMovimentoEstoque from '@/servicos/Estoques/ServicoMovimentoEstoque';
import { IDTOMovimentoEstoque } from '@/models/DTO/Estoques/MovimentoEstoque/IDTOMovimentoEstoque';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';

export default defineComponent({
  name: 'MovimentacaoEstoque',
  components: {
    Card,
    TituloPadrao,
    SelecionarDataInicialFinal,
    MensagemSemDados,
    Totalizador,
    SelecionarEmpresa,
    SelecionarEstoque,
    SelecionarRecursoMovimento,
    SelecionarProduto,
    SelecionarPeriodoMovimento,
  },
  setup(props) {
    const {
      telaBase, adicionarAtalho, removerAtalho, apresentarMensagemAlerta,
    } = useTelaBase();

    const { gradeBase } = useGradeBase();
    const servicoMovimentoEstoque = new ServicoMovimentoEstoque();
    const state = reactive({
      larguraGrade: window.innerWidth,
      parametrosConsulta: {} as IParametrosConsultaMovimentoEstoque,
      movimentacoes: [] as IDTOMovimentoEstoque[],
      controleResultado: 1,
      codigoEmpresa: 0,
      saldoInicial: 0,
      totalEntradas: 0,
      totalSaidas: 0,
      saldoBloqueado: 0,
      casasDecimaisTotalizadores: 0,
    });

    state.parametrosConsulta.periodo = EPeriodoMovimentoEstoque.MesAtual;
    state.codigoEmpresa = storeSistema.getters.codigoEmpresaOperacao();

    gradeBase.colunas = [
      {
        title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 0, visible: false, ordering: false,
      },
      {
        title: 'Recurso', dataIndex: 'recurso', key: 'Recurso', ellipsis: true, width: 250, position: 1, visible: true, ordering: false, align: 'left',
      },
      {
        title: 'Data', dataIndex: 'data', key: 'Data', width: 100, position: 3, visible: true, ordering: false, align: 'center',
      },
      {
        title: 'UN', dataIndex: 'unidade', key: 'Unidade', ellipsis: true, width: 50, position: 4, visible: true, ordering: false, align: 'center',
      },
      {
        title: 'Quantidade', dataIndex: 'quantidade', key: 'Quantidade', position: 5, width: 100, visible: true, ordering: false, align: 'right',
      },
    ];

    function montaApresentacaoQuantidade(movimentoEstoque: IDTOMovimentoEstoque): string {
      let quantidadeFormatada = '0';

      quantidadeFormatada = UtilitarioMascara.mascararValor(movimentoEstoque.quantidade, movimentoEstoque.casasDecimais);
      switch (movimentoEstoque.tipoMovimento) {
        case ETipoMovimentoEstoque.Entrada:
          quantidadeFormatada += ' Entrada';
          break;
        case ETipoMovimentoEstoque.Saida:
          quantidadeFormatada += ' Saída';
          break;
        case ETipoMovimentoEstoque.Bloqueio:
          quantidadeFormatada += ' B';
          break;
        default:
          quantidadeFormatada += ' ';
      }

      return quantidadeFormatada;
    }

    function limparInformacoesBusca() {
      state.controleResultado += 1;
      state.saldoInicial = 0;
      state.totalEntradas = 0;
      state.totalSaidas = 0;
      state.saldoBloqueado = 0;
      state.casasDecimaisTotalizadores = 0;
      state.parametrosConsulta.empresas = [];
      state.parametrosConsulta.empresas.push(state.codigoEmpresa);
      state.movimentacoes = [];
    }
    async function buscarMovimentacoes() {
      limparInformacoesBusca();

      if (!UtilitarioGeral.validaCodigo(state.parametrosConsulta.codigoProdutoDefinicao)) {
        apresentarMensagemAlerta('É necessário selecionar um produto!');
        return;
      }
      gradeBase.nenhumRegistroEncontrado = false;
      gradeBase.buscandoDados = true;
      state.movimentacoes = await servicoMovimentoEstoque.obterMovimentacoes(state.parametrosConsulta);

      if (UtilitarioGeral.validaLista(state.movimentacoes)) {
        state.casasDecimaisTotalizadores = state.movimentacoes[0].casasDecimais;

        state.movimentacoes.forEach((movimentoEstoque) => {
          switch (movimentoEstoque.tipoMovimento) {
            case ETipoMovimentoEstoque.Entrada:
              state.totalEntradas += movimentoEstoque.quantidade;
              break;
            case ETipoMovimentoEstoque.Saida:
              state.totalSaidas += movimentoEstoque.quantidade;
              break;
            case ETipoMovimentoEstoque.Bloqueio:
              state.saldoBloqueado += movimentoEstoque.quantidade;
              break;
            default:
              state.saldoBloqueado += 0;
          }
        });
      } else {
        gradeBase.nenhumRegistroEncontrado = true;
      }

      gradeBase.buscandoDados = false;
    }

    function calcularSaldoFinal():number {
      return ((state.saldoInicial + state.totalEntradas) - state.totalSaidas);
    }

    function preparaMensagemGrade():string {
      if (gradeBase.buscandoDados) {
        return 'Buscando informações, por favor aguarde...';
      }

      if (gradeBase.nenhumRegistroEncontrado) {
        return 'Nenhuma movimentação foi encontrada, verifique os seus filtros...';
      }

      return 'Preencha os filtros para localizar as movimentações';
    }

    function atualizarSelecaoEmpresas() {
      limparInformacoesBusca();
      state.parametrosConsulta.codigoProdutoDefinicao = 0;
      state.parametrosConsulta.estoques = undefined;
    }
    return {
      props,
      state,
      telaBase,
      gradeBase,
      storeSistema,
      EPeriodoMovimentoEstoque,
      UtilitarioData,
      atualizarSelecaoEmpresas,
      adicionarAtalho,
      removerAtalho,
      preparaMensagemGrade,
      buscarMovimentacoes,
      calcularSaldoFinal,
      montaApresentacaoQuantidade,
      limparInformacoesBusca,
    };
  },
});
