
import {
  computed, defineComponent, reactive, ref,
} from 'vue';
import { IOptionString } from '@/core/models/AntDesign/IOption';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';

export default defineComponent({
  name: 'SelecionarRecursoMovimento',
  props: {
    varios: {
      type: Boolean,
      default: false,
    },
    recursoSelecionado: {
      type: String || undefined,
    },
    recursosSelecionados: {
      type: Array as () => string[],
      default: () => [],
    },
    empresas: {
      type: Array as () => number[],
      default: () => [],
    },
    apenasMovimentoEstoque: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:recursoSelecionado', 'update:recursosSelecionados', 'change'],
  setup(props, { emit }) {
    const refSelecionarRecursoMovimento = ref<HTMLElement | null>(null);

    let debounce = 0;
    const servicoSistema = new ServicoSistema();

    const state = reactive({
      listaRecursos: [] as IOptionString[],
      buscandoDados: false,
      emDigitacao: false,
    });

    async function pesquisarRecurso(valorPesquisa: any) {
      state.emDigitacao = true;

      state.buscandoDados = true;
      state.listaRecursos = [];
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const listaRecursos = await servicoSistema.obterRecursos(valorPesquisa, props.empresas, props.apenasMovimentoEstoque);
        state.listaRecursos = listaRecursos.map((item) => ({
          label: item.descricao,
          value: item.identificador,
        }));

        state.buscandoDados = false;
      }, 600);
    }

    function obterRecurso():any {
      return (props.recursoSelecionado === '' ? undefined : props.recursoSelecionado);
    }

    const computedCodigoSelecionado = computed({
      get: () => obterRecurso(),
      set: (val: string) => {
        emit('update:recursoSelecionado', val);
      },
    });

    function obterRecursos():string[] {
      return props.recursosSelecionados;
    }

    const computedCodigosSelecionados = computed({
      get: () => obterRecursos(),
      set: (val: string[]) => {
        emit('update:recursosSelecionados', val);
      },
    });

    function change() {
      emit('change', props.varios ? props.recursosSelecionados : props.recursoSelecionado);
    }

    function focus() {
      if (refSelecionarRecursoMovimento.value) {
        refSelecionarRecursoMovimento.value.focus();
      }
    }

    return {
      props,
      state,
      refSelecionarRecursoMovimento,
      pesquisarRecurso,
      computedCodigoSelecionado,
      computedCodigosSelecionados,
      change,
      focus,
    };
  },
});
