import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IDTOTransferenciaEstoque } from '@/models/DTO/Estoques/TransferenciaEstoques/IDTOTransferenciaEstoque';
import { ITransferenciaEstoque } from '@/models/Entidades/Estoques/ITransferenciaEstoque';
import { ParametrosConsultaTransferenciaEstoque } from '@/models/ParametrosRequisicao/Estoques/TransferenciaEstoques/ParametrosConsultaTransferenciaEstoque';
/**
 * Serviço Transferência de Estoques
 * Fornece todas regras de negócios e lógicas relacionado a Transferência de Estoques.
 */

class ServicoTransferenciaEstoque {
    endPoint = 'transferencias-estoques';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterTransferencias(parametrosConsulta:ParametrosConsultaTransferenciaEstoque): Promise<IDTOTransferenciaEstoque[]> {
      let parametrosAdicionais = '';

      parametrosAdicionais = this.apiERP.obterParametroEmpresas(parametrosConsulta.empresas);
      if (parametrosConsulta.estoques !== undefined) {
        parametrosConsulta.estoques.forEach((codigoEstoque) => {
          parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
          parametrosAdicionais += `estoques=${codigoEstoque}`;
        });
      }

      if (parametrosConsulta.codigosProdutosDefinicoes !== undefined) {
        parametrosConsulta.codigosProdutosDefinicoes.forEach((codigoProdutoDefinicao) => {
          parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
          parametrosAdicionais += `codigosProdutosDefinicoes=${codigoProdutoDefinicao}`;
        });
      }

      if (parametrosConsulta.usuarios !== undefined) {
        parametrosConsulta.usuarios.forEach((codigoUsuario) => {
          parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
          parametrosAdicionais += `usuarios=${codigoUsuario}`;
        });
      }

      if (parametrosConsulta.tiposProdutos !== undefined) {
        parametrosConsulta.tiposProdutos.forEach((tipoProduto) => {
          parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
          parametrosAdicionais += `tiposProdutos=${tipoProduto}`;
        });
      }

      if (UtilitarioGeral.valorValido(parametrosConsulta.dataInicial)) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `&dataInicial=${parametrosConsulta.dataInicial}`;
      }

      if (UtilitarioGeral.valorValido(parametrosConsulta.dataFinal)) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `&dataFinal=${parametrosConsulta.dataFinal}`;
      }

      const result: any = await this.apiERP.get(`${this.endPoint}${parametrosAdicionais}`);
      return result.data;
    }

    public async transferirEstoque(objeto:ITransferenciaEstoque): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/transferir`, objeto);
      return result.data;
    }
}
export default ServicoTransferenciaEstoque;
