
import {
  defineComponent, reactive, ref, watch,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import SelecionarEmpresa from '@/components/MeuSistema/Empresas/SelecionarEmpresa.vue';
import SelecionarStatusBalancoEstoque from '@/components/Estoques/BalancoEstoque/SelecionarStatusBalancoEstoque.vue';
import SelecionarUsuario from '@/components/MeuSistema/Usuarios/SelecionarUsuario.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import BalancoEstoqueItens from '@/components/Estoques/BalancoEstoque/BalancoEstoqueItens.vue';
import BalancoEstoqueRevisao from '@/components/Estoques/BalancoEstoque/BalancoEstoqueRevisao.vue';
import BalancoEstoqueFinalizacao from '@/components/Estoques/BalancoEstoque/BalancoEstoqueFinalizacao.vue';
import BalancoEstoqueResumo from '@/components/Estoques/BalancoEstoque/BalancoEstoqueResumo.vue';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { IBalancoEstoque, IBalancoEstoqueConferente } from '@/models/Entidades/Estoques/IBalancoEstoque';
import ServicoBalancoEstoque from '@/servicos/Estoques/ServicoBalancoEstoque';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import storeSistema from '@/store/storeSistema';
import { EStatusBalancoEstoque } from '@/models/Enumeradores/Estoques/BalancoEstoque/EStatusBalancoEstoque';
import { IDTOBalancoEstoqueItemResumo } from '@/models/DTO/Estoques/BalancoEstoque/IDTOBalancoEstoqueItemResumo';
import { IDTOBalancoEstoqueRevisaoEstatisticas } from '@/models/DTO/Estoques/BalancoEstoque/IDTOBalancoEstoqueRevisaoEstatisticas';

export default defineComponent({
  name: 'BalancoEstoqueModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    SelecionarData,
    SelecionarEmpresa,
    SelecionarUsuario,
    SelecionarStatusBalancoEstoque,
    BalancoEstoqueItens,
    BalancoEstoqueRevisao,
    BalancoEstoqueFinalizacao,
    BalancoEstoqueResumo,
    RequisicaoModal,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissao,
      verificaConceitoParaApresentarEmpresas, apresentarMensagemSucesso,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso,
      ocultarBarraProgresso, sincronizarRegistro,
    } = useModalBase(props, emit);

    const refBalancoEstoqueRevisao = ref<InstanceType<typeof BalancoEstoqueRevisao>>();
    const refBalancoEstoqueResumo = ref<InstanceType<typeof BalancoEstoqueResumo>>();

    const servicoBalancoEstoque = new ServicoBalancoEstoque();
    telaBase.identificadorRecurso = 'BALANCO_ESTOQUE';
    telaBase.identificadorPermissao = 'AUT_BALANCO_ESTOQUE';

    const state = reactive({
      passo: 0,
      balancoEstoque: {} as IBalancoEstoque,
      tiposProdutosSelecionados: [] as number[],
      consolidacaoEstoqueItem: {} as IDTOBalancoEstoqueItemResumo,
      atualizarDadosRevisao: true,
      buscarDadosResumo: true,
      salvarAlteracoes: false,
      estatisticasRevisao: {} as IDTOBalancoEstoqueRevisaoEstatisticas,
    });

    function objetoInicial() {
      state.passo = 0;
      state.atualizarDadosRevisao = true;
      state.buscarDadosResumo = true;
      state.salvarAlteracoes = false;
      state.balancoEstoque = {} as IBalancoEstoque;
      state.balancoEstoque.codigo = 0;
      state.balancoEstoque.codigoEmpresa = props.operacao.empresaSelecionada;
      state.balancoEstoque.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
      state.balancoEstoque.quantidadeConferentes = 1;
      state.balancoEstoque.zerarProdutosNaoContados = false;
      state.balancoEstoque.conferentes = [];
      state.balancoEstoque.conferentes.push({
        codigo: 1, codigoBalancoEstoque: 1, ordem: 0,
      } as IBalancoEstoqueConferente);
      state.balancoEstoque.status = EStatusBalancoEstoque.Aberto;

      telaBase.empresasSelecionadas = [];
      state.tiposProdutosSelecionados = [];

      (async () => {
        state.balancoEstoque.dataInicio = await new ServicoSistema().obterDataAtual();
      })();
    }

    function prepararConferentes() {
      if (state.balancoEstoque.codigo > 0) {
        state.salvarAlteracoes = true;
      }
      if (UtilitarioGeral.valorValido(state.balancoEstoque.quantidadeConferentes)) {
        if (state.balancoEstoque.quantidadeConferentes === 0) {
          state.balancoEstoque.quantidadeConferentes = 1;
        }
      } else {
        state.balancoEstoque.quantidadeConferentes = 1;
      }
      if (UtilitarioGeral.validaLista(state.balancoEstoque.conferentes)) {
        if (state.balancoEstoque.conferentes.length < state.balancoEstoque.quantidadeConferentes) {
          while (state.balancoEstoque.conferentes.length < state.balancoEstoque.quantidadeConferentes) {
            const conferente:IBalancoEstoqueConferente = {} as IBalancoEstoqueConferente;
            conferente.codigo = 0;
            conferente.codigoBalancoEstoque = state.balancoEstoque.codigo;
            conferente.ordem = 0;
            state.balancoEstoque.conferentes.push(conferente);
          }
        } else if (state.balancoEstoque.quantidadeConferentes < state.balancoEstoque.conferentes.length) {
          while (state.balancoEstoque.quantidadeConferentes < state.balancoEstoque.conferentes.length) {
            state.balancoEstoque.conferentes.splice((state.balancoEstoque.conferentes.length - 1), 1);
          }
        }
      }
    }

    async function salvar() {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      apresentarBarraProgresso();

      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir && state.balancoEstoque.codigo === 0) {
        retorno = await servicoBalancoEstoque.incluir(state.balancoEstoque);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoBalancoEstoque.alterar(state.balancoEstoque);
      }

      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
          apresentarMensagemSucesso('Balanço do estoque iniciado com sucesso!');
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
          apresentarMensagemSucesso('Balanço do estoque atualizado com sucesso!');
        }
        state.balancoEstoque.codigo = retorno.codigoRegistro;
        state.passo = 0;
        state.salvarAlteracoes = false;
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    async function balancoFinalizado() {
      state.balancoEstoque = await servicoBalancoEstoque.obter(state.balancoEstoque.codigo, state.balancoEstoque.codigoEmpresa);
      if (refBalancoEstoqueResumo.value) {
        await refBalancoEstoqueResumo.value.buscarDados();
        state.buscarDadosResumo = false;
      }
      apresentarMensagemSucesso('Balanço finalizado com sucesso!');
      state.passo = 4;
    }

    function verificaApresentacaoRevisao():boolean {
      if (state.estatisticasRevisao.quantidadeItens === 0) {
        return false;
      }

      if (storeSistema.getters.codigoUsuarioAutenticado() === state.balancoEstoque.codigoUsuario) {
        return true;
      }

      if (!state.balancoEstoque.conferentes.some((c) => c.codigoUsuario === storeSistema.getters.codigoUsuarioAutenticado())) {
        return true;
      }

      return false;
    }

    function verificaApresentacaoFinalizar():boolean {
      if (state.atualizarDadosRevisao === false
          && state.balancoEstoque.status === EStatusBalancoEstoque.Aberto
          && state.estatisticasRevisao.quantidadeItens > 0) {
        if (state.estatisticasRevisao.quantidadeItens === state.estatisticasRevisao.revisados) {
          return true;
        }
      }

      return false;
    }

    async function atualizarDadosRevisao() {
      state.estatisticasRevisao = await servicoBalancoEstoque.obterEstatisticasRevisao(state.balancoEstoque.codigo, state.balancoEstoque.codigoEmpresa);
      state.atualizarDadosRevisao = false;
    }
    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      if (modalBase.computedVisivel) {
        await obterPermissoes(ETipoPermissao.Autorizacoes);
        await preencherEmpresasComEstrategiaPermissao();

        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          state.balancoEstoque = await servicoBalancoEstoque.obter(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
          if (state.balancoEstoque.status === EStatusBalancoEstoque.Aberto) {
            state.estatisticasRevisao = await servicoBalancoEstoque.obterEstatisticasRevisao(state.balancoEstoque.codigo, state.balancoEstoque.codigoEmpresa);
          }
        }
        verificaConceitoParaApresentarEmpresas();
      }
      telaBase.carregando = false;
    });

    watch(async () => state.passo, async () => {
      if (state.passo === 2) {
        if (refBalancoEstoqueRevisao.value && state.atualizarDadosRevisao) {
          await refBalancoEstoqueRevisao.value.buscarDados();
          state.atualizarDadosRevisao = false;
        }
      } else if (state.passo === 4) {
        if (refBalancoEstoqueResumo.value && state.buscarDadosResumo) {
          await refBalancoEstoqueResumo.value.buscarDados();
          state.buscarDadosResumo = false;
        }
      }
    });

    return {
      telaBase,
      props,
      modalBase,
      state,
      salvar,
      objetoInicial,
      EPermissaoDados,
      UtilitarioGeral,
      EStatusBalancoEstoque,
      refBalancoEstoqueRevisao,
      refBalancoEstoqueResumo,
      prepararConferentes,
      verificaApresentacaoRevisao,
      verificaApresentacaoFinalizar,
      balancoFinalizado,
      atualizarDadosRevisao,
    };
  },
});
