import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "ant-row" }
const _hoisted_3 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_StreamBarcodeReader = _resolveComponent("StreamBarcodeReader")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_ctx.computedVisivel)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_a_modal, {
          keyboard: false,
          centered: "",
          width: 500,
          visible: _ctx.computedVisivel,
          "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedVisivel) = $event)),
          title: _ctx.props.titulo,
          footer: null
        }, {
          closeIcon: _withCtx(() => [
            _createVNode(_component_icone, { nome: "voltar" })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_a_form, { layout: "vertical" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_StreamBarcodeReader, { onDecode: _ctx.codigoIdentificadoNaLeitura }, null, 8, ["onDecode"])
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["visible", "title"])
      ]))
    : _createCommentVNode("", true)
}