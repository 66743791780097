
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { Modal } from 'ant-design-vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import Icone from '@/core/components/Icone.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import BuscaAvancada from '@/core/components/BuscaAvancada/BuscaAvancada.vue';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { IBuscaAvancada } from '@/core/models/BuscaAvancada/IBuscaAvancada';
import PreferenciasColuna from '@/core/components/Preferencias/ColunaTabela.vue';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IListaPaginadaMetaData } from '@/core/models/Consulta/IListaPaginada';
import { IColumn, ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IPagination } from '@/core/models/AntDesign/IPagination';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import TituloPadrao from './Tela/TituloPadrao.vue';
import { IServicoBase } from '@/core/models/IServicoBase';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { EVinculoEmpresa } from '@/core/models/Enumeradores/EEVinculoEmpresa';
import { ETipoArquivo } from '../models/Enumeradores/ETipoArquivo';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import { ITelaComunicacao } from '@/core/models/ITelaComunicacao';
import { ITelaEstrutura } from '../models/ITelaEstrutura';
import storeSistema from '@/store/storeSistema';
import UtilitarioGeral from '../utilitarios/UtilitarioGeral';
import UtilitarioMascara from '../utilitarios/UtilitarioMascara';
import { useGradeBase } from '../composables/GradeBase';
import Card from './Tela/Card.vue';

const dados: any[] = [];

export interface IListaSelecionados {
  key: number;
}

export default defineComponent({
  name: 'TelaPadraoCrud',
  components: {
    Icone,
    MensagemSemDados,
    PreferenciasColuna,
    BuscaAvancada,
    Card,
    TituloPadrao,
  },
  emits: ['exibir-modal', 'sincroniza-tela-crud'],
  props: {
    estrutura: {
      type: Object as () => ITelaEstrutura,
      required: true,
    },
    servicoAPI: {
      type: Object as () => IServicoBase<any>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissao,
      defineEmpresasSelecionadasComPermissao,
      montaObjetoPreferencia, salvarPreferencias, verificaConceitoParaApresentarEmpresas,
      apresentarMensagemSucesso, apresentarMensagemAlerta, apresentarMensagemErro,
      adicionarAtalho, removerAtalho,
    } = useTelaBase();

    const {
      gradeBase, preencheOrdenacaoSelecionada, ordenacaoAtiva,
    } = useGradeBase();

    const tela = reactive({
      dados,
      colunas: props.estrutura.colunasGrade,
      exibirGuia: false,
      exibirHistorico: false,
      atalhoAdicionado: false,
      windowHeight: (window.innerHeight - 300),
      windowWidth: (window.innerWidth - 100),
      exibirPersonalizarColunas: false,
      exibirBuscaAvancada: false,
      buscaAvancada: {} as IBuscaAvancada,
      paginacao: {} as IPagination,
      paginacaoApi: { totalRegistros: 0, paginaAtual: 1 } as IListaPaginadaMetaData,
      timerRegistros: 0,
      totalRegistrosComMascara: '100',
    });

    if (window.innerWidth < 900) {
      tela.windowWidth = 600;
      tela.windowHeight = window.innerHeight;
    }
    telaBase.empresasSelecionadas = [];
    gradeBase.ordenacaoSelecionada = [];
    function sincronizaTelaComponentePai() {
      const telaCrud: ITelaComunicacao = {
        empresasSelecionadas: telaBase.empresasSelecionadas,
        codigosSelecionados: gradeBase.codigosSelecionados,
        ordenacaoSelecionada: gradeBase.ordenacaoSelecionada,
        filtrosAplicados: gradeBase.filtrosAplicados,
        permissaoVisualizar: telaBase.permissaoDados.visualizar,
        permissaoIncluir: telaBase.permissaoDados.imprimir,
        permissaoAlterar: telaBase.permissaoDados.alterar,
        permissaoExcluir: telaBase.permissaoDados.excluir,
        permissaoImprimir: telaBase.permissaoDados.imprimir,
      };
      emit('sincroniza-tela-crud', telaCrud);
    }

    async function buscarDados() {
      tela.dados = [];
      if (telaBase.empresasSelecionadas.length === 0) {
        apresentarMensagemAlerta('Nenhuma empresa foi selecionada!');
        return;
      }

      gradeBase.filtrosAplicados = [];
      tela.buscaAvancada.filtrosAdicionados.forEach((item) => {
        gradeBase.filtrosAplicados.push(item.filtro);
      });

      const parametrosConsulta = {} as IParametrosConsulta;
      parametrosConsulta.empresas = telaBase.empresasSelecionadas;
      parametrosConsulta.numeroPagina = tela.paginacao.current;
      parametrosConsulta.qtdeRegistrosPagina = tela.paginacao.pageSize;
      parametrosConsulta.qtdeRegistrosTotal = tela.paginacao.total;
      parametrosConsulta.ordenacao = Array<string>();

      gradeBase.ordenacaoSelecionada.forEach((item) => {
        parametrosConsulta.ordenacao.push(`${item.identificador}|${item.ordem}`);
      });

      if (!UtilitarioGeral.validaLista(gradeBase.ordenacaoSelecionada)) {
        props.estrutura.ordenacaoPadraoGrade.forEach((item) => {
          parametrosConsulta.ordenacao.push(`${item.identificador}|${item.ordem}`);
        });
      }

      telaBase.carregando = true;

      const listaPaginada = await props.servicoAPI.buscaAvancada(parametrosConsulta, gradeBase.filtrosAplicados);
      tela.dados = listaPaginada.dados;
      tela.paginacaoApi = listaPaginada.metaData;
      telaBase.carregando = false;
      sincronizaTelaComponentePai();
    }

    async function imprimir(tipoArquivoRelatorio:ETipoArquivo, codigoRegistro?:number) {
      gradeBase.filtrosAplicados = [];

      if (telaBase.empresasSelecionadas.length === 0) {
        apresentarMensagemAlerta('Nenhuma empresa foi selecionada!');
        return;
      }
      tela.buscaAvancada.filtrosAdicionados.forEach((item) => {
        gradeBase.filtrosAplicados.push(item.filtro);
      });
      const parametrosConsulta = {} as IParametrosConsulta;
      parametrosConsulta.empresas = telaBase.empresasSelecionadas;
      parametrosConsulta.numeroPagina = 1;
      parametrosConsulta.qtdeRegistrosPagina = 0;
      parametrosConsulta.qtdeRegistrosTotal = 0;
      parametrosConsulta.ordenacao = Array<string>();

      gradeBase.ordenacaoSelecionada.forEach((item) => {
        parametrosConsulta.ordenacao.push(`${item.identificador}|${item.ordem}`);
      });
      let codigosSelecionados: number[] = [];
      if (codigoRegistro !== undefined && codigoRegistro > 0) {
        codigosSelecionados.push(codigoRegistro);
      } else {
        codigosSelecionados = gradeBase.codigosSelecionados;
      }

      telaBase.carregando = true;
      const retornoRelatorio = await props.servicoAPI.relatorioPadrao(tipoArquivoRelatorio, parametrosConsulta, gradeBase.filtrosAplicados, codigosSelecionados);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        window.open(retornoRelatorio.link, '_blank');
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
      sincronizaTelaComponentePai();
    }
    async function carregarPreferencias() {
      telaBase.preferencias = await new ServicoSistema().obterPreferenciaRecurso(telaBase.identificadorRecurso, telaBase.empresasSelecionadas);
      if (telaBase.preferencias !== null) {
        const preferenciaFiltros = telaBase.preferencias.find(((preferencia) => preferencia.chave === 'busca-avancada-filtros'));
        if (preferenciaFiltros !== undefined && preferenciaFiltros !== null) {
          tela.buscaAvancada.filtrosAdicionados = JSON.parse(preferenciaFiltros.valor);
          tela.buscaAvancada.chaveFiltrosAdicionados = (tela.buscaAvancada.filtrosAdicionados.length) + 1;
        }
        const preferenciaOrdenacao = telaBase.preferencias.find(((preferencia) => preferencia.chave === 'busca-avancada-ordenacao'));
        if (preferenciaOrdenacao !== undefined && preferenciaOrdenacao !== null) {
          gradeBase.ordenacaoSelecionada = JSON.parse(preferenciaOrdenacao.valor);
        }
        const preferenciaColunas = telaBase.preferencias.find(((preferencia) => preferencia.chave === 'personalizacao-colunas'));
        if (preferenciaColunas !== undefined && preferenciaColunas !== null) {
          if (preferenciaColunas.valor !== '[]' && preferenciaColunas.valor !== '') {
            const colunasDB: IColumn[] = JSON.parse(preferenciaColunas.valor);
            const colunasNovasPreferencias: IColumn[] = [];
            tela.colunas.forEach((coluna) => {
              const preferenciaCol = colunasDB.find(((colunaDB) => colunaDB.key === coluna.key));
              const colunaNova:IColumn = coluna;
              if (preferenciaCol !== undefined && preferenciaCol !== null) {
                colunaNova.visible = preferenciaCol.visible;
                colunaNova.fixed = preferenciaCol.fixed;
                colunaNova.align = preferenciaCol.align;
                colunaNova.width = preferenciaCol.width;
                colunaNova.position = preferenciaCol.position;
                colunasNovasPreferencias.push(colunaNova);
              }
            });
            tela.colunas = colunasNovasPreferencias.sort((x, y) => x.position - y.position);
          }
        } else {
          tela.colunas = props.estrutura.colunasGrade;
        }
      }
    }

    onBeforeMount(async () => {
      try {
        telaBase.identificadorRecurso = props.estrutura.identificadorRecurso;
        telaBase.identificadorPermissao = props.estrutura.identificadorPermissao;
        telaBase.apresentarEmpresas = false;
        await obterPermissoes(ETipoPermissao.Autorizacoes);
        await preencherEmpresasComEstrategiaPermissao();
        await defineEmpresasSelecionadasComPermissao();
        if (props.estrutura.apresentarEmpresas) { verificaConceitoParaApresentarEmpresas(); }

        sincronizaTelaComponentePai();
        telaBase.propriedadesConsulta = await props.servicoAPI.obterPropriedadesConsulta();
        if (telaBase.propriedadesConsulta.length > 0) {
          tela.buscaAvancada.filtros = telaBase.propriedadesConsulta.filter((item) => item.filtro === true);
          tela.buscaAvancada.ordenacao = telaBase.propriedadesConsulta.filter((item) => item.ordenacao === true);
        }

        tela.buscaAvancada.filtrosAdicionados = [];
        tela.buscaAvancada.chaveFiltrosAdicionados = 0;
        await carregarPreferencias();

        tela.paginacao.total = 100;
        tela.paginacao.showSizeChanger = true;
        tela.paginacao.pageSize = 10;
        tela.paginacao.pageSizeOptions = ['10', '50', '100', '500', '1000'];
        await buscarDados();
      } catch (error) {
        // console.log(telaBase.propriedadesConsulta);
        // console.log(error);
      }
    });

    async function ordenarDados(key: string, sort: string) {
      await preencheOrdenacaoSelecionada(key, sort);
      await buscarDados();
    }

    function showModal() {
      const telaOperacao = {} as ITelaOperacao;
      telaOperacao.tipoPermissaoDados = EPermissaoDados.Incluir;
      telaOperacao.listaPermissoesDados = telaBase.listaPermissoesDados;
      telaOperacao.codigoRegistro = 0;
      telaOperacao.empresaSelecionada = telaBase.empresasSelecionadas[0];
      emit('exibir-modal', telaOperacao);
    }
    function editarModal(codigoRegistro: number) {
      const telaOperacao = {} as ITelaOperacao;
      telaOperacao.tipoPermissaoDados = EPermissaoDados.Visualizar;
      telaOperacao.listaPermissoesDados = telaBase.listaPermissoesDados;
      telaOperacao.codigoRegistro = codigoRegistro;
      telaOperacao.empresaSelecionada = telaBase.empresasSelecionadas[0];
      emit('exibir-modal', telaOperacao);
    }

    async function excluirRegistro(codigo: number) {
      const retorno = await props.servicoAPI.excluir(codigo);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        tela.dados = tela.dados.filter((item: any) => item.codigo !== codigo);
      } else if (retorno.status === EStatusRetornoRequisicao.Alerta) {
        Modal.warning({
          title: 'Não foi possível concluir a exclusão!',
          content: retorno.mensagem,
        });
      }
    }

    function confirmaExclusao(objeto: any) {
      const identificacaoRegistro = props.servicoAPI.obterIdentificacaoRegistroGradeCRUD(objeto);

      Modal.confirm({
        title: props.estrutura.mensagemExclusao,
        content: `${identificacaoRegistro} ?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: () => { excluirRegistro(objeto.codigo); },
      });
    }

    async function sincronizarRegistro(acao: EPermissaoDados, codigoRegistro: number) {
      if (acao === EPermissaoDados.Incluir || acao === EPermissaoDados.Alterar) {
        const parametrosConsulta: IParametrosConsulta = {} as IParametrosConsulta;

        if (!UtilitarioGeral.validaLista(telaBase.empresasSelecionadas)) {
          telaBase.empresasSelecionadas = storeSistema.getters.empresasUsuarioAutenticado();
        }

        parametrosConsulta.empresas = telaBase.empresasSelecionadas;
        parametrosConsulta.codigosSelecionados = [];

        parametrosConsulta.codigosSelecionados.push(codigoRegistro);
        const resultadoBusca = await props.servicoAPI.buscaAvancada(parametrosConsulta);
        if (UtilitarioGeral.validaLista(resultadoBusca.dados)) {
          if (acao === EPermissaoDados.Incluir) {
            tela.dados.push(resultadoBusca.dados[0]);
          } else if (acao === EPermissaoDados.Alterar) {
            const index = tela.dados.findIndex((c) => c.codigo === codigoRegistro);
            if (index >= 0) {
              tela.dados[index] = resultadoBusca.dados[0];
            }
          }
        }
      }
    }
    function apresentarGuia() {
      tela.exibirGuia = !tela.exibirGuia;
    }
    function apresentarHistorico() {
      tela.exibirHistorico = !tela.exibirHistorico;
    }
    function apresentarBuscaAvancada() {
      tela.exibirPersonalizarColunas = false;
      tela.exibirBuscaAvancada = !tela.exibirBuscaAvancada;
    }
    function apresentarPersonalizarColuna() {
      tela.exibirBuscaAvancada = false;
      tela.exibirPersonalizarColunas = !tela.exibirPersonalizarColunas;
    }

    async function aplicarFiltros() {
      tela.paginacao.current = 1;
      await buscarDados();
    }

    async function alterarQuantidadeRegistroTotal(valor: any) {
      clearTimeout(tela.timerRegistros);
      let totalRegistros = 100;
      tela.timerRegistros = setTimeout(async () => {
        if (UtilitarioGeral.valorValido(valor)) {
          const limiteRegistros = UtilitarioMascara.removerMascaraNumero(valor);
          if (limiteRegistros > 0) {
            totalRegistros = limiteRegistros;
          }
        }
        tela.paginacao.total = totalRegistros;
        tela.totalRegistrosComMascara = UtilitarioMascara.mascararNumeroInteiro(totalRegistros);
        await buscarDados();
      }, 600);
    }

    async function onPageChange(current: number, pageSize: number) {
      tela.paginacao.current = current;
      tela.paginacao.pageSize = pageSize;
      await buscarDados();
    }
    async function onShowSizeChange(current: number, pageSize: number) {
      tela.paginacao.current = 1;
      tela.paginacao.pageSize = pageSize;
      await buscarDados();
    }

    async function atualizarSelecaoEmpresas() {
      sincronizaTelaComponentePai();
      carregarPreferencias();
      await buscarDados();
    }

    async function salvarFiltrosBuscaAvancada() {
      await salvarPreferencias('Filtros salvos com sucesso!', 'busca-avancada-filtros',
        JSON.stringify(tela.buscaAvancada.filtrosAdicionados), telaBase.empresasSelecionadas);
    }

    async function salvarOrdenacaoBuscaAvancada() {
      await salvarPreferencias('Ordenação salva com sucesso!', 'busca-avancada-ordenacao',
        JSON.stringify(gradeBase.ordenacaoSelecionada), telaBase.empresasSelecionadas);
    }

    async function salvarPersonalizacaoColunas() {
      const colunasPersistencia:IColumn[] = []; let posicao = 0;
      tela.colunas.forEach((coluna) => {
        const colunaNova = coluna;
        colunaNova.position = posicao;
        colunasPersistencia.push(colunaNova);
        posicao += 1;
      });
      await salvarPreferencias('Personalizações salvas com sucesso!', 'personalizacao-colunas',
        JSON.stringify(colunasPersistencia.sort((c) => c.position)), telaBase.empresasSelecionadas);
    }

    async function redefinirPersonalizacaoColunas() {
      const retorno = await new ServicoSistema().redefinirPreferenciaUsuario(montaObjetoPreferencia('personalizacao-colunas', '', telaBase.empresasSelecionadas));
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        tela.colunas = props.estrutura.colunasGrade;
        apresentarMensagemSucesso('Personalizações redefinidas com sucesso!');
      } else {
        apresentarMensagemAlerta(retorno.mensagem);
      }
    }

    async function salvarTodasPreferencias() {
      await salvarFiltrosBuscaAvancada();
      await salvarOrdenacaoBuscaAvancada();
      await salvarPersonalizacaoColunas();
    }

    const onSelectChange = (selectedRowKeys: any) => {
      gradeBase.codigosSelecionados = selectedRowKeys;
      sincronizaTelaComponentePai();
    };
    return {
      telaBase,
      gradeBase,
      tela,
      props,
      ordenarDados,
      ordenacaoAtiva,
      showModal,
      editarModal,
      confirmaExclusao,
      excluirRegistro,
      sincronizarRegistro,
      apresentarGuia,
      apresentarHistorico,
      apresentarBuscaAvancada,
      apresentarPersonalizarColuna,
      aplicarFiltros,
      onPageChange,
      onShowSizeChange,
      alterarQuantidadeRegistroTotal,
      buscarDados,
      imprimir,
      onSelectChange,
      ECustomRenderRow,
      EVinculoEmpresa,
      ETipoArquivo,
      atualizarSelecaoEmpresas,
      salvarFiltrosBuscaAvancada,
      salvarOrdenacaoBuscaAvancada,
      salvarPersonalizacaoColunas,
      salvarTodasPreferencias,
      redefinirPersonalizacaoColunas,
      storeSistema,
      adicionarAtalho,
      removerAtalho,
      UtilitarioMascara,
    };
  },
});
