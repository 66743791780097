
import { defineComponent, ref } from 'vue';
import TelaPadraoAutorizacao from '@/core/components/TelaPadraoAutorizacao.vue';
import BalancoEstoqueModal from '@/views/Estoques/BalancoEstoque/BalancoEstoqueModal.vue';
import { useCrudBase } from '@/core/composables/CrudBase';
import { IOrdenacao } from '@/core/models/Consulta/IOrdenacao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import ServicoBalancoEstoque from '@/servicos/Estoques/ServicoBalancoEstoque';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';

export default defineComponent({
  name: 'BalancoEstoque',
  components: {
    TelaPadraoAutorizacao,
    BalancoEstoqueModal,
  },
  setup() {
    const {
      crudBase, preencherEstrutura, exibirModal, sincronizaTelaCrud,
    } = useCrudBase();
    const servicoBalancoEstoque = new ServicoBalancoEstoque();
    const refCrudBalancoEstoque = ref<InstanceType<typeof TelaPadraoAutorizacao>>();

    crudBase.estrutura = preencherEstrutura();
    crudBase.estrutura.tipoPermissao = ETipoPermissao.Autorizacoes;
    crudBase.estrutura.apresentarEmpresas = true;
    crudBase.estrutura.botaoPrincipalAcao = 'Novo';
    crudBase.estrutura.colunasGrade = [
      {
        title: 'Número', dataIndex: 'codigo', key: 'CodigoBalancoEstoque', position: 0, visible: true, ordering: true, width: 90, customRenderRow: ECustomRenderRow.LinkAcao,
      },
      {
        title: 'Descrição', dataIndex: 'descricao', key: 'DescricaoBalancoEstoque', position: 1, visible: true, ordering: true, customRenderRow: ECustomRenderRow.LinkAcao,
      },
      {
        title: 'Responsável', dataIndex: 'responsavel', key: 'Responsavel', position: 2, visible: true, align: 'left',
      },
      {
        title: 'Início', dataIndex: 'dataInicio', key: 'DataInicioBalancoEstoque', position: 3, visible: true, ordering: true, align: 'left',
      },
      {
        title: 'Fim', dataIndex: 'dataFim', key: 'DataFimBalancoEstoque', position: 4, visible: true, ordering: true, align: 'left',
      },
      {
        title: 'Status', dataIndex: 'statusDescricao', key: 'StatusBalancoEstoque', position: 5, visible: true, align: 'left',
      },
    ];
    crudBase.estrutura.ordenacaoPadraoGrade.push({ identificador: 'Codigo', ordem: 'DESC' } as IOrdenacao);

    async function sincronizarRegistro(acao: EPermissaoDados, codigoRegistro: number) {
      if (refCrudBalancoEstoque.value !== undefined) {
        await refCrudBalancoEstoque.value.sincronizarRegistro(acao, codigoRegistro);
      }
    }
    return {
      crudBase,
      servicoBalancoEstoque,
      refCrudBalancoEstoque,
      exibirModal,
      sincronizaTelaCrud,
      sincronizarRegistro,
    };
  },
});
