
import { defineComponent, reactive } from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import ServicoBalancoEstoque from '@/servicos/Estoques/ServicoBalancoEstoque';
import BarraProgresso from '@/core/components/Tela/BarraProgresso.vue';
import Icone from '@/core/components/Icone.vue';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';

export default defineComponent({
  name: 'BalancoEstoqueFinalizacao',
  props: {
    codigoBalancoEstoque: {
      type: Number,
      required: true,
    },
    statusBalancoEstoque: {
      type: Number,
      required: true,
    },
  },
  components: {
    BarraProgresso,
    Icone,
  },
  emits: ['balancoFinalizado'],
  setup(props, { emit }) {
    const { apresentarMensagemAlerta } = useTelaBase();

    const servicoBalancoEstoque = new ServicoBalancoEstoque();

    const state = reactive({
      zerarProdutosNaoContados: false,
      finalizacaoEmAndamento: false,
    });

    async function finalizarBalanco() {
      state.finalizacaoEmAndamento = true;
      const retornoFinalizacao = await servicoBalancoEstoque.finalizar(props.codigoBalancoEstoque, state.zerarProdutosNaoContados);

      if (retornoFinalizacao.status === EStatusRetornoRequisicao.Sucesso) {
        emit('balancoFinalizado');
      } else {
        apresentarMensagemAlerta(retornoFinalizacao.mensagem);
      }
    }

    return {
      props,
      state,
      finalizarBalanco,
    };
  },
});
