import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
/**
 * Serviço Importação estoques
 * Fornece todas regras de negócios e lógicas relacionado a importação de registros de estoques.
 */

class ServicoImportacaoEstoques {
    endPoint = 'importacao-estoques';

    apiERP = new ApiERP();

    public async importarBalancoEstoque(codigoBalanco: number, codigoEstoque:number, arquivo: FormData): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/balanco/${codigoBalanco}/estoque/${codigoEstoque}`, arquivo);
      return result.data;
    }

    public async obterArquivoPadraoBalancoEstoque(codigoEmpresa: number, preencherProdutos?: boolean): Promise<string> {
      let parametrosAdicionais = '';
      if (preencherProdutos !== undefined) {
        parametrosAdicionais += `?preencherProdutos=${preencherProdutos}`;
      }

      const result: any = await this.apiERP.get(`${this.endPoint}/arquivo-padrao-balanco/${codigoEmpresa}${parametrosAdicionais}`);
      return result.data;
    }
}
export default ServicoImportacaoEstoques;
