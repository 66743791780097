
import { defineComponent, computed } from 'vue';
import Icone from '@/core/components/Icone.vue';

export default defineComponent({
  name: 'ProdutoDetalhes',
  props: {
    codigoProdutoDefinicao: {
      type: Number,
      required: true,
    },
    visivel: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Icone,
  },
  emits: ['update:visivel'],
  setup(props, { emit }) {
    const computedVisivel = computed({
      get: () => props.visivel,
      set: (val: boolean) => {
        emit('update:visivel', val);
      },
    });

    return {
      computedVisivel,
    };
  },
});
