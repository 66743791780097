
import {
  defineComponent, reactive, ref, watch,
} from 'vue';
import type { TreeProps } from 'ant-design-vue';
import { DataNode } from 'ant-design-vue/lib/tree';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useGradeBase } from '@/core/composables/GradeBase';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import Card from '@/core/components/Tela/Card.vue';
import Icone from '@/core/components/Icone.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import storeSistema from '@/store/storeSistema';
import SelecionarEmpresa from '@/components/MeuSistema/Empresas/SelecionarEmpresa.vue';
import SelecionarProduto from '@/components/Cadastros/Produtos/SelecionarProduto.vue';
import SelecionarPessoa from '@/components/Cadastros/Pessoas/SelecionarPessoa.vue';
import SelecionarTipoProduto from '@/components/Cadastros/Produtos/SelecionarTipoProduto.vue';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoEstoque from '@/servicos/Cadastros/Estoques/ServicoEstoque';
import { IEstoque } from '@/models/Entidades/Cadastros/Estoques/IEstoque';
import { ETipoEstoque } from '@/models/Enumeradores/Cadastros/Estoques/ETipoEstoque';
import { IDTOConsultaEstoque } from '@/models/DTO/Estoques/IDTOConsultaEstoque';
import { IParametrosConsultaEstoque } from '@/models/ParametrosRequisicao/Estoques/IParametrosConsultaEstoque';

export default defineComponent({
  name: 'MapaEstoque',
  components: {
    TituloPadrao,
    Card,
    Icone,
    MensagemSemDados,
    SelecionarEmpresa,
    SelecionarProduto,
    SelecionarTipoProduto,
    SelecionarPessoa,
  },
  setup(props) {
    const {
      telaBase, adicionarAtalho, removerAtalho, apresentarMensagemAlerta,
    } = useTelaBase();

    const { gradeBase } = useGradeBase();
    const servicoEstoque = new ServicoEstoque();

    const treeData = ref<TreeProps['treeData']>([]);

    const state = reactive({
      parametrosConsulta: {} as IParametrosConsultaEstoque,
      produtos: [] as IDTOConsultaEstoque[],
      controleResultado: 1,
      estoquesCadastrados: [] as IEstoque[],
      estoquesExpandidos: [] as number[],
      estoquesSelecionados: [] as number[],
    });

    function arvoreEstoques(estoques:IEstoque[], codigoEstoquePai: number | null) {
      const treeDataNova: TreeProps['treeData'] = [];
      estoques
        .filter((e) => e.codigoEstoquePai === codigoEstoquePai)
        .forEach((d) => {
          const estoqueNode:DataNode = {} as DataNode;
          estoqueNode.key = d.codigo;
          estoqueNode.title = servicoEstoque.montaApresentacaoEstoque(d);
          estoqueNode.disabled = (d.tipo === ETipoEstoque.Sintetico);
          estoqueNode.children = [];

          estoqueNode.children = arvoreEstoques(estoques, d.codigo);
          return treeDataNova.push(estoqueNode);
        });
      return treeDataNova;
    }
    (async () => {
      state.estoquesCadastrados = await servicoEstoque.obterTodosEstoques(storeSistema.getters.codigoEmpresaOperacao());
      const treeDataNova: TreeProps['treeData'] = arvoreEstoques(state.estoquesCadastrados, null);
      treeData.value = treeDataNova;

      state.estoquesExpandidos = state.estoquesCadastrados.filter((c) => c.tipo === ETipoEstoque.Sintetico).map((e) => e.codigo);
    })();

    telaBase.empresasSelecionadas = storeSistema.getters.empresasUsuarioAutenticado();

    function prepararGrade() {
      gradeBase.colunas = [
        {
          title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 0, visible: false, ordering: false,
        },
        {
          title: 'Código Interno', dataIndex: 'codigoInterno', key: 'CodigoInterno', ellipsis: true, width: 120, position: 1, visible: true, ordering: false, fixed: 'left',
        },
        {
          title: 'Produto', dataIndex: 'produto', key: 'Produto', ellipsis: true, width: 300, position: 2, visible: true, ordering: false, fixed: 'left',
        },
        {
          title: 'Marca', dataIndex: 'marca', key: 'Marca', ellipsis: true, width: 150, position: 3, visible: true, ordering: false, align: 'left',
        },
        {
          title: 'UN', dataIndex: 'unidade', key: 'Unidade', ellipsis: true, width: 50, position: 4, visible: true, ordering: false, align: 'center',
        },
        {
          title: 'Empresa', dataIndex: 'empresa', key: 'Empresa', visible: (telaBase.empresasSelecionadas.length > 1), ellipsis: true, width: 150, position: 5, ordering: false, align: 'left',
        },
        {
          title: 'Estoque', dataIndex: 'estoque', key: 'Estoque', visible: (state.parametrosConsulta.filtrarTodosEstoques), ellipsis: true, width: 150, position: 6, ordering: false, align: 'left',
        },
        {
          title: 'Quantidade', dataIndex: 'quantidade', key: 'Quantidade', position: 7, width: 150, visible: true, ordering: false, align: 'right',
        },
        {
          title: 'Qtde Bloqueada', dataIndex: 'quantidadeBloqueada', key: 'QuantidadeBloqueada', position: 8, width: 150, visible: true, ordering: false, align: 'right',
        },
        {
          title: 'NCM', dataIndex: 'ncm', key: 'Ncm', ellipsis: true, position: 9, visible: true, ordering: false, align: 'left',
        },

      ];
    }

    prepararGrade();

    function limparInformacoesBusca() {
      state.controleResultado += 1;
      state.produtos = [];
    }
    async function consultaEstoque() {
      limparInformacoesBusca();
      if (!UtilitarioGeral.validaLista(telaBase.empresasSelecionadas)) {
        apresentarMensagemAlerta('Selecione pelo menos uma empresa!');
        return;
      }

      state.parametrosConsulta.empresas = telaBase.empresasSelecionadas;
      state.parametrosConsulta.estoques = state.estoquesSelecionados;
      prepararGrade();
      gradeBase.nenhumRegistroEncontrado = false;
      gradeBase.buscandoDados = true;
      state.produtos = await servicoEstoque.consultaEstoquesComProdutos(state.parametrosConsulta);

      if (!UtilitarioGeral.validaLista(state.produtos)) {
        gradeBase.nenhumRegistroEncontrado = true;
      }

      gradeBase.buscandoDados = false;
    }

    function preparaMensagemGrade():string {
      if (gradeBase.buscandoDados) {
        return 'Buscando informações, por favor aguarde...';
      }

      if (gradeBase.nenhumRegistroEncontrado) {
        return 'Nenhum produto foi encontrado, verifique os seus filtros...';
      }

      return 'Preencha os filtros para localizar os produtos';
    }

    function atualizarSelecaoEmpresas() {
      limparInformacoesBusca();
      state.parametrosConsulta.produtosDefinicoes = undefined;
      state.parametrosConsulta.tiposProdutos = undefined;
      state.parametrosConsulta.utilizadores = undefined;
      state.parametrosConsulta.estoques = undefined;
      prepararGrade();
    }

    function prepararFiltroEstoque(valor:boolean) {
      if (valor) {
        state.estoquesSelecionados = [];
      }
    }

    watch(async () => state.estoquesSelecionados, async () => {
      if (state.estoquesSelecionados.length > 0) {
        state.parametrosConsulta.filtrarTodosEstoques = false;
        prepararGrade();
        await consultaEstoque();
      }
    });
    return {
      props,
      state,
      telaBase,
      gradeBase,
      storeSistema,
      treeData,
      UtilitarioMascara,
      UtilitarioData,
      atualizarSelecaoEmpresas,
      adicionarAtalho,
      removerAtalho,
      preparaMensagemGrade,
      consultaEstoque,
      limparInformacoesBusca,
      prepararFiltroEstoque,
    };
  },
});
