
import { defineComponent, reactive } from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useGradeBase } from '@/core/composables/GradeBase';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarDataInicialFinal from '@/core/components/Tela/SelecionarDataInicialFinal.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import storeSistema from '@/store/storeSistema';
import SelecionarEmpresa from '@/components/MeuSistema/Empresas/SelecionarEmpresa.vue';
import SelecionarUsuario from '@/components/MeuSistema/Usuarios/SelecionarUsuario.vue';
import SelecionarEstoque from '@/components/Cadastros/Estoques/SelecionarEstoque.vue';
import SelecionarProduto from '@/components/Cadastros/Produtos/SelecionarProduto.vue';
import SelecionarTipoProduto from '@/components/Cadastros/Produtos/SelecionarTipoProduto.vue';
import TransferenciaEstoqueModal from './TransferenciaEstoqueModal.vue';
import ServicoTransferenciaEstoque from '@/servicos/Estoques/ServicoTransferenciaEstoque';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ParametrosConsultaTransferenciaEstoque } from '@/models/ParametrosRequisicao/Estoques/TransferenciaEstoques/ParametrosConsultaTransferenciaEstoque';
import { IDTOTransferenciaEstoque } from '@/models/DTO/Estoques/TransferenciaEstoques/IDTOTransferenciaEstoque';

export default defineComponent({
  name: 'TransferenciaEstoque',
  components: {
    TituloPadrao,
    Card,
    SelecionarDataInicialFinal,
    MensagemSemDados,
    SelecionarEmpresa,
    SelecionarUsuario,
    SelecionarEstoque,
    SelecionarProduto,
    SelecionarTipoProduto,
    TransferenciaEstoqueModal,
  },
  setup(props) {
    const {
      telaBase, adicionarAtalho, removerAtalho, apresentarMensagemAlerta,
    } = useTelaBase();

    const { gradeBase } = useGradeBase();
    const servicoTransferenciaEstoque = new ServicoTransferenciaEstoque();
    const state = reactive({
      larguraGrade: window.innerWidth,
      parametrosConsulta: {} as ParametrosConsultaTransferenciaEstoque,
      transferencias: [] as IDTOTransferenciaEstoque[],
      controleResultado: 1,
      apresentarModalTransferencia: false,
    });
    telaBase.empresasSelecionadas = storeSistema.getters.empresasUsuarioAutenticado();

    gradeBase.colunas = [
      {
        title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 0, visible: false, ordering: false,
      },
      {
        title: 'Empresa Origem', dataIndex: 'empresaOrigem', key: 'EmpresaOrigem', ellipsis: true, width: 150, position: 1, visible: true, ordering: false, align: 'left',
      },
      {
        title: 'Estoque Origem', dataIndex: 'estoqueOrigem', key: 'EstoqueOrigem', ellipsis: true, width: 150, position: 2, visible: true, ordering: false, align: 'left',
      },
      {
        title: 'Empresa Destino', dataIndex: 'empresaDestino', key: 'EmpresaDestino', ellipsis: true, width: 150, position: 3, visible: true, ordering: false, align: 'left',
      },
      {
        title: 'Estoque Destino', dataIndex: 'estoqueDestino', key: 'EstoqueDestino', ellipsis: true, width: 150, position: 4, visible: true, ordering: false, align: 'left',
      },
      {
        title: 'Usuário', dataIndex: 'usuario', key: 'Usuario', ellipsis: true, width: 150, position: 5, visible: true, ordering: false, align: 'left',
      },
      {
        title: 'Data e Hora', dataIndex: 'dataHora', key: 'DataHora', width: 200, position: 6, visible: true, ordering: false, align: 'center',
      },
      {
        title: 'Produto', dataIndex: 'produto', key: 'Produto', ellipsis: true, width: 300, position: 4, visible: true, ordering: false, align: 'left',
      },
      {
        title: 'UN', dataIndex: 'unidade', key: 'Unidade', ellipsis: true, width: 100, position: 8, visible: true, ordering: false, align: 'center',
      },
      {
        title: 'Quantidade', dataIndex: 'quantidade', key: 'Quantidade', position: 9, visible: true, ordering: false, align: 'right',
      },

    ];

    function limparInformacoesBusca() {
      state.controleResultado += 1;
      state.transferencias = [];
    }
    async function buscarMovimentacoes() {
      limparInformacoesBusca();
      state.parametrosConsulta.empresas = telaBase.empresasSelecionadas;

      if (!UtilitarioGeral.valorValido(state.parametrosConsulta.dataInicial)) {
        apresentarMensagemAlerta('É necessário informar a Data inicial!');
        return;
      }

      if (!UtilitarioGeral.valorValido(state.parametrosConsulta.dataFinal)) {
        apresentarMensagemAlerta('É necessário informar a Data final!');
        return;
      }
      gradeBase.nenhumRegistroEncontrado = false;
      gradeBase.buscandoDados = true;
      state.transferencias = await servicoTransferenciaEstoque.obterTransferencias(state.parametrosConsulta);

      if (!UtilitarioGeral.validaLista(state.transferencias)) {
        gradeBase.nenhumRegistroEncontrado = true;
      }

      gradeBase.buscandoDados = false;
    }

    function preparaMensagemGrade():string {
      if (gradeBase.buscandoDados) {
        return 'Buscando informações, por favor aguarde...';
      }

      if (gradeBase.nenhumRegistroEncontrado) {
        return 'Nenhuma transferência foi encontrada, verifique os seus filtros...';
      }

      return 'Preencha os filtros para localizar as transferências';
    }

    function atualizarSelecaoEmpresas() {
      limparInformacoesBusca();
      state.parametrosConsulta.codigosProdutosDefinicoes = undefined;
      state.parametrosConsulta.tiposProdutos = undefined;
      state.parametrosConsulta.usuarios = undefined;
      state.parametrosConsulta.estoques = undefined;
    }
    return {
      props,
      state,
      telaBase,
      gradeBase,
      storeSistema,
      UtilitarioMascara,
      UtilitarioData,
      atualizarSelecaoEmpresas,
      adicionarAtalho,
      removerAtalho,
      preparaMensagemGrade,
      buscarMovimentacoes,
      limparInformacoesBusca,
    };
  },
});
