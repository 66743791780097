
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import SelecionarEmpresa from '@/components/MeuSistema/Empresas/SelecionarEmpresa.vue';
import SelecionarUsuario from '@/components/MeuSistema/Usuarios/SelecionarUsuario.vue';
import SelecionarProduto from '@/components/Cadastros/Produtos/SelecionarProduto.vue';
import SelecionarEstoque from '@/components/Cadastros/Estoques/SelecionarEstoque.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import ProdutoDetalhes from '@/components/Cadastros/Produtos/ProdutoDetalhes.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITransferenciaEstoque } from '@/models/Entidades/Estoques/ITransferenciaEstoque';
import ServicoTransferenciaEstoque from '@/servicos/Estoques/ServicoTransferenciaEstoque';
import storeSistema from '@/store/storeSistema';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import ServicoProduto from '@/servicos/Cadastros/Produtos/ServicoProduto';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoUnidade from '@/servicos/Cadastros/Produtos/ServicoUnidade';
import { IUnidade } from '@/models/Entidades/Cadastros/Produtos/IUnidade';
import ServicoEstoque from '@/servicos/Cadastros/Estoques/ServicoEstoque';
import { IEstoqueItem } from '@/models/Entidades/Cadastros/Estoques/IEstoqueItem';

export default defineComponent({
  name: 'TransferenciaEstoqueModal',
  props: {
    visivel: {
      type: Boolean,
    },
  },
  components: {
    Icone,
    Card,
    CampoNumerico,
    SelecionarData,
    SelecionarEmpresa,
    SelecionarUsuario,
    SelecionarProduto,
    SelecionarEstoque,
    RequisicaoModal,
    ProdutoDetalhes,
  },
  emits: ['sincronizarRegistro', 'update:visivel'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemSucesso, apresentarMensagemAlerta } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);
    const servicoTransferenciaEstoque = new ServicoTransferenciaEstoque();
    const servicoEstoque = new ServicoEstoque();
    const state = reactive({
      transferenciaEstoque: {} as ITransferenciaEstoque,
      apresentarDetalhesProduto: false,
      quantidadeAtualOrigem: 0,
      quantidadeBloqueadaOrigem: 0,
      quantidadeAposTransferenciaOrigem: 0,
      quantidadeAtualDestino: 0,
      quantidadeBloqueadaDestino: 0,
      quantidadeAposTransferenciaDestino: 0,
      unidadesCadastradas: [] as IUnidade[],
      unidadeProduto: {} as IUnidade,
      buscandoInformacoesEstoqueOrigem: false,
      buscandoInformacoesEstoqueDestino: false,
    });

    (async () => {
      state.unidadesCadastradas = await new ServicoUnidade().obterTodasUnidades(storeSistema.getters.empresasUsuarioAutenticado());
    })();

    async function objetoInicial() {
      state.transferenciaEstoque = {} as ITransferenciaEstoque;
      state.transferenciaEstoque.codigo = 0;
      state.transferenciaEstoque.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
      state.transferenciaEstoque.dataTransferencia = await new ServicoSistema().obterDataAtual();
      telaBase.empresasSelecionadas = [];
      telaBase.empresasSelecionadas = storeSistema.getters.empresasUsuarioAutenticado();
      state.unidadeProduto = {} as IUnidade;
      state.unidadeProduto.codigo = 0;
      state.unidadeProduto.casasDecimais = 0;
      state.unidadeProduto.descricao = '';
      state.unidadeProduto.sigla = '';
    }

    async function transferirEstoque() {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      apresentarBarraProgresso();

      retorno = await servicoTransferenciaEstoque.transferirEstoque(state.transferenciaEstoque);

      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        modalBase.computedVisivel = false;
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      if (modalBase.computedVisivel) {
        await objetoInicial();
      }
    });

    async function obterDadosIdentificacaoDefinicao(codigoDefinicao:number) {
      if (UtilitarioGeral.validaCodigo(codigoDefinicao)) {
        const dadosIdentificacaoApi = await new ServicoProduto().obterDadosIdentificacaoDefinicao(codigoDefinicao);
        if (UtilitarioGeral.objetoValido(dadosIdentificacaoApi)) {
          const unidade = state.unidadesCadastradas.find((c) => c.codigo === dadosIdentificacaoApi.codigoUnidade);
          if (unidade !== undefined && unidade !== null) {
            state.unidadeProduto = unidade;
          }
        }
      }
    }

    async function buscarInformacoesEstoqueOrigem() {
      state.buscandoInformacoesEstoqueOrigem = true;
      state.quantidadeAtualOrigem = 0;
      state.transferenciaEstoque.codigoEstoqueItemOrigem = 0;

      if (!UtilitarioGeral.validaCodigo(state.transferenciaEstoque.codigoProdutoDefinicao)) {
        apresentarMensagemAlerta('É necessário selecionar um produto!');
        return;
      }

      if (!UtilitarioGeral.validaCodigo(state.transferenciaEstoque.codigoEmpresaOrigem)) {
        apresentarMensagemAlerta('É necessário selecionar a empresa de origem!');
        return;
      }

      if (!UtilitarioGeral.validaCodigo(state.transferenciaEstoque.codigoEstoqueOrigem)) {
        apresentarMensagemAlerta('É necessário selecionar o estoque de origem!');
        return;
      }

      const estoqueItem = await servicoEstoque.obterItemEstoqueInformacoesProduto(state.transferenciaEstoque.codigoEstoqueOrigem,
        state.transferenciaEstoque.codigoProdutoDefinicao, state.unidadeProduto.codigo,
        state.transferenciaEstoque.codigoEmpresaOrigem);

      if (estoqueItem !== undefined) {
        if (estoqueItem.codigo > 0) {
          state.transferenciaEstoque.codigoEstoqueItemOrigem = estoqueItem.codigo;
          state.quantidadeAtualOrigem = await servicoEstoque.obterQuantidadeDisponivelItemEstoque(estoqueItem.codigo);
        }
      } else {
        apresentarMensagemAlerta('Produto sem quantidades disponíveis neste estoque!');
      }

      state.buscandoInformacoesEstoqueOrigem = false;
    }

    async function buscarInformacoesEstoqueDestino() {
      state.buscandoInformacoesEstoqueDestino = true;
      state.quantidadeAtualDestino = 0;
      state.transferenciaEstoque.codigoEstoqueItemDestino = 0;

      if (!UtilitarioGeral.validaCodigo(state.transferenciaEstoque.codigoProdutoDefinicao)) {
        apresentarMensagemAlerta('É necessário selecionar um produto!');
        return;
      }

      if (!UtilitarioGeral.validaCodigo(state.transferenciaEstoque.codigoEmpresaDestino)) {
        apresentarMensagemAlerta('É necessário selecionar a empresa de destino!');
        return;
      }

      if (!UtilitarioGeral.validaCodigo(state.transferenciaEstoque.codigoEstoqueDestino)) {
        apresentarMensagemAlerta('É necessário selecionar o estoque de destino!');
        return;
      }

      let estoqueItem = await servicoEstoque.obterItemEstoqueInformacoesProduto(state.transferenciaEstoque.codigoEstoqueDestino,
        state.transferenciaEstoque.codigoProdutoDefinicao, state.unidadeProduto.codigo,
        state.transferenciaEstoque.codigoEmpresaDestino);

      if (!UtilitarioGeral.objetoValido(estoqueItem)) {
        estoqueItem = {} as IEstoqueItem;
        estoqueItem.codigo = 0;
        estoqueItem.codigoEmpresa = state.transferenciaEstoque.codigoEmpresaDestino;
        estoqueItem.codigoEstoque = state.transferenciaEstoque.codigoEstoqueDestino;
        estoqueItem.codigoProdutoDefinicao = state.transferenciaEstoque.codigoProdutoDefinicao;
        estoqueItem.codigoUnidade = state.unidadeProduto.codigo;
        estoqueItem.bloqueado = false;

        const retornoItemEstoque = await servicoEstoque.incluirItens(state.transferenciaEstoque.codigoEstoqueDestino, [estoqueItem]);
        if (retornoItemEstoque.status === EStatusRetornoRequisicao.Sucesso) {
          state.transferenciaEstoque.codigoEstoqueItemDestino = retornoItemEstoque.codigoRegistro;
        } else {
          apresentarMensagemAlerta(retornoItemEstoque.mensagem);
        }
      } else if (estoqueItem.codigo > 0) {
        state.transferenciaEstoque.codigoEstoqueItemDestino = estoqueItem.codigo;
      }

      if (state.transferenciaEstoque.codigoEstoqueItemDestino > 0) {
        state.quantidadeAtualDestino = await servicoEstoque.obterQuantidadeDisponivelItemEstoque(state.transferenciaEstoque.codigoEstoqueItemDestino);
      }
      state.buscandoInformacoesEstoqueDestino = false;
    }
    return {
      telaBase,
      props,
      modalBase,
      state,
      transferirEstoque,
      objetoInicial,
      obterDadosIdentificacaoDefinicao,
      buscarInformacoesEstoqueOrigem,
      buscarInformacoesEstoqueDestino,
    };
  },
});
