
import { computed, defineComponent } from 'vue';
import { StreamBarcodeReader } from 'vue-barcode-reader';
import Icone from '@/core/components/Icone.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'LeitorCodigoCamera',
  props: {
    titulo: {
      type: String,
      default: 'Aproxime o código de barras',
    },
    visivel: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Icone,
    StreamBarcodeReader,
  },
  emits: ['codigoIdentificadoNaLeitura', 'update:visivel'],
  setup(props, { emit }) {
    const computedVisivel = computed({
      get: () => props.visivel,
      set: (valor: boolean) => {
        emit('update:visivel', valor);
      },
    });

    function codigoIdentificadoNaLeitura(codigo:string) {
      if (UtilitarioGeral.valorValido(codigo)) {
        const audio = new Audio('https://sabresistemas.com.br/audio/beep.mp3');
        audio.play();
        emit('codigoIdentificadoNaLeitura', codigo);
        computedVisivel.value = false;
      }
    }
    return {
      props,
      computedVisivel,
      codigoIdentificadoNaLeitura,
    };
  },
});
