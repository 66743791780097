
import {
  defineComponent, reactive, computed,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useGradeBase } from '@/core/composables/GradeBase';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import Totalizador from '@/core/components/Tela/Totalizador.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import ServicoBalancoEstoque from '@/servicos/Estoques/ServicoBalancoEstoque';
import { IDTOBalancoEstoqueItemResumo } from '@/models/DTO/Estoques/BalancoEstoque/IDTOBalancoEstoqueItemResumo';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'BalancoEstoqueResumo',
  props: {
    codigoBalancoEstoque: {
      type: Number,
      required: true,
    },
    codigoEmpresa: {
      type: Number,
      required: true,
    },
  },
  components: {
    MensagemSemDados,
    CampoNumerico,
    Totalizador,
  },
  setup(props) {
    const { telaBase, apresentarMensagemAlerta } = useTelaBase();

    const { gradeBase } = useGradeBase();

    const servicoBalancoEstoque = new ServicoBalancoEstoque();

    const state = reactive({
      itens: [] as IDTOBalancoEstoqueItemResumo[],
      quantidadeItens: 0,
      larguraGrade: 1200,
    });

    function verificaPosicaoColuna():string | undefined {
      if (UtilitarioDispositivo.larguraTelaMobile()) {
        return undefined;
      }
      return 'left';
    }
    async function montaGradeProdutos() {
      gradeBase.colunas = [
        {
          title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 0, visible: false, ordering: false,
        },
        {
          title: 'Código Interno', dataIndex: 'codigoInterno', key: 'CodigoInternoProduto', width: 60, ellipsis: true, position: 1, visible: true, ordering: false, fixed: verificaPosicaoColuna(), align: 'right',
        },
        {
          title: 'Produto', dataIndex: 'produto', key: 'DescricaoProduto', width: 250, ellipsis: true, position: 2, visible: true, ordering: false, fixed: verificaPosicaoColuna(), align: 'left',
        },
        {
          title: 'UN', dataIndex: 'sigla', key: 'UnidadeProduto', width: 30, ellipsis: true, position: 3, visible: true, ordering: false, fixed: verificaPosicaoColuna(), align: 'center',
        },
        {
          title: 'Estoque', dataIndex: 'estoque', key: 'estoque', width: 70, position: 4, visible: true, ordering: false, align: 'left',
        },
        {
          title: 'Qtde.Estoque', dataIndex: 'quantidadeEstoque', key: 'QuantidadeEstoque', width: 100, position: 5, visible: true, ordering: false, align: 'right',
        },
        {
          title: 'Ajuste', dataIndex: 'quantidadeAjuste', key: 'QuantidadeAjuste', width: 100, position: 6, visible: true, ordering: false, align: 'right',
        },
        {
          title: 'Saldo Final', dataIndex: 'quantidadeSaldoFinal', key: 'QuantidadeSaldoFinal', width: 100, position: 7, visible: true, ordering: false, align: 'right',
        },
      ];
    }

    state.itens = [];

    function obtemCodigoBalanco():number {
      montaGradeProdutos();
      return props.codigoBalancoEstoque;
    }
    const computedCodigoBalancoEstoque = computed(() => obtemCodigoBalanco());

    async function buscarDados() {
      state.itens = [];
      state.quantidadeItens = 0;

      gradeBase.buscandoDados = true;
      if (props.codigoEmpresa === 0) {
        apresentarMensagemAlerta('Nenhuma empresa foi selecionada!');
        return;
      }

      const resumo = await servicoBalancoEstoque.obterItensResumo(props.codigoBalancoEstoque, props.codigoEmpresa);
      if (UtilitarioGeral.validaLista(resumo)) {
        state.itens = resumo;
        state.quantidadeItens = resumo.length;
      }
      gradeBase.buscandoDados = false;
    }

    return {
      props,
      state,
      telaBase,
      gradeBase,
      computedCodigoBalancoEstoque,
      buscarDados,
    };
  },
});
